import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import scrollImage from "../assets/Images/DigitalTransformation/2_2x.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import BannerBgImage from "../assets/Images/DigirytePods/DigirytePods-banner.png";
import ClickToViewCardImg1 from "../assets/Images/DigirytePods/ClickToViewCard/Self-SustainableTeams.jpg";
import ClickToViewCardImg2 from "../assets/Images/DigirytePods/ClickToViewCard/Cross-functionalTeams.jpg";
import ClickToViewCardImg3 from "../assets/Images/DigirytePods/ClickToViewCard/AverageTeam.jpg";
import ClickToViewCardImg4 from "../assets/Images/DigirytePods/ClickToViewCard/All-inclusiveStructure.jpg";
import ClickToViewCardImg5 from "../assets/Images/DigirytePods/ClickToViewCard/Single-product-backlog.jpg";
import ClickToViewCardImg6 from "../assets/Images/DigirytePods/ClickToViewCard/DomainandOperationsSME.png";

import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import MiniImageBannerBgImg1 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import ogimage from "../assets/Images/ogImages/OG_DigirytePods.jpg";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
import ClickToViewCard from "../templates/ClickToViewCard";

import ClickToViewMegaViewCarousal from "../templates/ClickToViewMegaViewCarousal";
import ClickToViewMegaViewCarousalImg1 from "../assets/Images/ClickToViewMegaViewCarousal/Lean_startup_mindset.png";
import ClickToViewMegaViewCarousalImg2 from "../assets/Images/ClickToViewMegaViewCarousal/Contact_and_first_proposal.png";
import ClickToViewMegaViewCarousalImg3 from "../assets/Images/ClickToViewMegaViewCarousal/Product_Workshops_with_the_team.png";
import ClickToViewMegaViewCarousalImg4 from "../assets/Images/ClickToViewMegaViewCarousal/Final_proposal_and_release_plan.png";

import DigirytePodsNoShadow from "../assets/Images/mobileView/DigirytePodsNoShadow.png";
//PlainCard
import PlainCard from "../components/SixPlainCard";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//BlurCardList
import BlurCardList from "../templates/BlurCardList";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Miniview
import Miniview from "../templates/MiniView";

//Image slider (Clients)
import ImageSlider from "../templates/ImageSlider";
// import SliderImg1 from "../assets/Images";

import EightPlainCards from "../templates/EightPlainCards";
import HoverCardWithBtnIcon11 from "../assets/Images/ProductScaling/Rapid-Growth.svg";
import HoverCardWithBtnIcon12 from "../assets/Images/ProductScaling/Incorporate-new-technologies.svg";
import HoverCardWithBtnIcon13 from "../assets/Images/ProductScaling/Improve-product-reliability.svg";
import HoverCardWithBtnIcon14 from "../assets/Images/ProductScaling/Speed.svg";
import HoverCardWithBtnIcon15 from "../assets/Images/ProductScaling/Improved-Efficiency.svg";
import HoverCardWithBtnIcon16 from "../assets/Images/ProductScaling/Non-disruptive.svg";
import HoverCardWithBtnIcon17 from "../assets/Images/ProductScaling/Secure-Design.svg";
import HoverCardWithBtnIcon18 from "../assets/Images/ProductScaling/ISO-27001-&-9001.svg";

//Medium View
import MediumviewWithHover from "../templates/MediumViewWithHover";
import MediumviewImage1 from "../assets/Images/DigirytePods/3.png";
import MediumviewImage2 from "../assets/Images/DigirytePods/4.png";
import MediumviewImage3 from "../assets/Images/DigirytePods/5.png";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

//Icon Card List
import IconCardList from "../templates/IconCardList";
import IconCardListIcon1 from "../assets/Images/Hire Remote team/Group 6127.svg";
import IconCardListIcon2 from "../assets/Images/Hire Remote team/Affiliate.svg";
import IconCardListIcon3 from "../assets/Images/Hire Remote team/Registration.svg";
import IconCardListIcon4 from "../assets/Images/Hire Remote team/Teamwork.svg";
import IconCardListIcon5 from "../assets/Images/Hire Remote team/Group 6142.svg";
import IconCardListIcon6 from "../assets/Images/Hire Remote team/Valuations.svg";
import IconCardListIcon7 from "../assets/Images/Hire Remote team/Digital Agreement.svg";
import IconCardListIcon8 from "../assets/Images/Hire Remote team/Investor.svg";
import IconCardListIcon9 from "../assets/Images/Hire Remote team/Online Shoping.svg";

//Six Hover Cards
import SixPlainCards from "../templates/SixPlainCards";
import HoverCardWithBtnIcon2 from "../assets/Images/DigirytePods/WhyDigiryte/25+Yrs.svg";
import HoverCardWithBtnIcon1 from "../assets/Images/DigirytePods/WhyDigiryte/ReadilyAvailable.svg";
import HoverCardWithBtnIcon5 from "../assets/Images/DigirytePods/WhyDigiryte/SkilledResource.svg";
import HoverCardWithBtnIcon4 from "../assets/Images/DigirytePods/WhyDigiryte/SOP.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/DigirytePods/WhyDigiryte/WellDefinedFramework.svg";

//HoverCardWithButton
import HoverCardWithButton from "../components/HoverCardWithButton";
import hoverCardImg15 from "../assets/Images/DigirytePods/digirytePods/AccesstoNewTech.svg";
import hoverCardImg11 from "../assets/Images/DigirytePods/digirytePods/accestoWidePool.svg";
import hoverCardImg16 from "../assets/Images/DigirytePods/digirytePods/NoNeedforCapacity.svg";
import hoverCardImg12 from "../assets/Images/DigirytePods/digirytePods/QuickgotoMarket.svg";
import hoverCardImg14 from "../assets/Images/DigirytePods/digirytePods/ReducedCost.svg";
import hoverCardImg13 from "../assets/Images/DigirytePods/digirytePods/ScaleyourBusiness.svg";

// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";
import BusinessBenifited from "../assets/Images/mobileView/Busines_Benefied.png";

import ourOffer from "../assets/Images/scrollImages/Group_6110_2x.png";

import ARUP from "../assets/Images/TeamAugmentation/BusinessBenefited/ARUP.png";
import Hedgehog from "../assets/Images/TeamAugmentation/BusinessBenefited/Hedgehog.png";
import MROPRO from "../assets/Images/TeamAugmentation/BusinessBenefited/MROPRO.png";
import CERN from "../assets/Images/TeamAugmentation/BusinessBenefited/CERN.png";
import NetworkPlus from "../assets/Images/TeamAugmentation/BusinessBenefited/NetworkPlus.png";
import VerticalLinearStepper from "../templates/VerticalStepper";

const getStepper = () => {
  return [
    "Designer & Developers ",
    "Quality Assurance Engineer ",
    "DevOps Engineer ",
    " Technical Architect",
  ];
};
const getStepper1 = () => {
  return [
    "UI Designer",
    "White Box Tester",
    "Automation Engineer",
    "MLOps etc..",
  ];
};
const getStepper2 = () => {
  return [
    "Governance with Ket stakeholders",
    "People Operations ",
    "Managing AS-IS Process",
    "Managing KPIs & KRAs",
  ];
};
const getStepper3 = () => {
  return [
    "Ensuring Product Market Fit ",
    "Ensuring the facts and details are correct",
    "Implementing domain-specific best practices",
    "Ensuring Definition of Done (DoD)",
  ];
};
const getStepper4 = () => {
  return [
    "Client Success Management",
    "Delivery Management ",
    "Resource Planning and Audit",
    "Agile Team Coaching",
  ];
};
const getStepper5 = () => {
  return [
    "GDPR Audit",
    "Data Protection Strategy ",
    "Training staff involved in the data processing",
    " Educating Pod members on Data protection",
  ];
};
const DigirytePods = () => {
  const stepper = getStepper();
  const stepper1 = getStepper1();
  const stepper2 = getStepper2();
  const stepper3 = getStepper3();
  const stepper4 = getStepper4();
  const stepper5 = getStepper5();
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(3);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=Digiryte Pods"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=Digiryte Pods&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <>
        Digiryte Pod <br />
        <div className="pod-bnr-img-subtitle">
          Digiryte Pod = Agile Pod.
          <br /> No confusion, just pure Agile power!
        </div>
      </>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Team Augmentation",
          link: "/team-augmentation",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Digiryte Pods",
          link: "/digiryte-pods",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Product Development",
          link: "/product-development",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Build Your Digiryte Pods, To Scale <br />
        <span className="red-line3letter">You</span>r Business Faster And Easier
      </div>
    ),
    InfoviewContent: (
      <>
        Digiryte Pods is a Premium Offshore Agile Pod which provides contractual
        arrangements in which you pay a fixed monthly cost for the people &
        Infra, with a commitment to execute your Standard Operating Procedures
        (SOP) and AS-IS process in offshore. This also includes a
        monthly/quarterly/ annual review on the agreed KRA and key
        accomplishments. The reason behind you opting Digiryte Pods is to make
        your business more cost-effective and enable your business to scale.
        <br /> <br /> Startups, Scaleups and Enterprises often consider Digiryte
        Pods. This approach gives them a perfect balance of price, speed and
        quality. The Digiryte Pods is now a popular business model to lower
        operating expenses and raise productivity levels by leveraging talent
        offshore. Also, it provides you the flexibility to engage with premium
        offshore tech resources based on your project requirements and suitable
        hiring models.
      </>
    ),
  };

  const InfoviewDataStepper = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        <span className="red-line3letter">Dig</span>iryte Pod Features & Team
      </div>
    ),
    InfoviewContent: (
      <>
        Agile pod teams are designed to be self-sufficient. The team is
        self-organizing and works with minimum supervision, creating a higher
        sense of ownership and maturity. Also, because most required expertise
        is available at hand within the team, there is a minimum level of
        dependency on people outside the pod. The pods stay together until the
        requirements keep coming for their team—say, for one release cycle.
      </>
    ),
  };
  /**
   * Infoview
   */
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        <span className="red-line3letter">Why</span> Digiryte?
      </div>
    ),
    InfoviewContent: (
      <>
        Digiryte provides a dedicated development centre with the required
        office infrastructure and manpower including administrative and support
        staff necessary to carry the development process to completion. This not
        only helps client business to scale but also help client to optimise
        their cost and enable the client to focus more on their business
        developments and other key value adds to their organization.
      </>
    ),
  };
  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Microservices based",
        HoverCardWithBtnsubtitle: "(Improves Agility)",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Microservices based",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "API-first",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "API-first",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
      },
      {
        HoverCardWithBtntitle: "Cloud-native SaaS",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Cloud-native SaaS",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
      },
      {
        HoverCardWithBtntitle: "Headless",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Headless",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Months to Minutes)",
      },
      {
        HoverCardWithBtntitle: "ISO 27001 Compliant",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "ISO 27001 Compliant",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Lifecyle)",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Hover card button
   */

  const hoverCardButtonData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Access to a Wide Pool Of Great Talent",
      HoverCardWithBtnIcon: hoverCardImg11,
      HoverCardWithBtnIconAlt: "Delivery",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Quick Go-to-Market",
      HoverCardWithBtnIcon: hoverCardImg12,
      HoverCardWithBtnIconAlt: "Processes",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Scales Your Business Easier",
      HoverCardWithBtnIcon: hoverCardImg13,
      HoverCardWithBtnIconAlt: "Output",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Reduced Costs",
      HoverCardWithBtnIcon: hoverCardImg14,
      HoverCardWithBtnIconAlt: "Delivery",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Access To The Newest Technologies",
      HoverCardWithBtnIcon: hoverCardImg15,
      HoverCardWithBtnIconAlt: "Processes",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "No Need For Capacity.",
      HoverCardWithBtnIcon: hoverCardImg16,
      HoverCardWithBtnIconAlt: "Output",
      HoverCardIconClass: "mb-10",
    },
  ];
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg1,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    // lastCardData: {
    //   title: "Let's Talk",
    //   subTitle: "We'd love to help with your product.",
    //   variant: "contained",
    //   label: "GET IN TOUCH",
    //   customClass: "miniCrdBgImg-main-border",
    //   route: "/contact-us",
    // },
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: scrollImage,
  };

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    customClass: "mbl-card-height",
    page: "digiryte-pods",
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Readily Available Setup Across Major Cities",
    HoverCardWithBtntitle2:
      "25+ years experienced management presence to oversee",
    HoverCardWithBtntitle3:
      "Well defined framework to make your business auto scale",
    HoverCardWithBtntitle4: "SOPs to kick start the process from Week 1",
    HoverCardWithBtntitle5: "Skilled Resource Availability On Demand",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContentClass: "btncontent mt-10 mbl-card-height",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      height: "490",
      service: "machineLearning",
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      tooltip: "yes",
      tip: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon4,
      altTxt:
        "Remote employees with English fluency, averaging over 160 hours of work a month",
      heading: (
        <>
          Remote Employees with
          <br /> English Fluency,
          <br /> Averaging Over 160
          <br /> Hours of Work a Month
        </>
      ),
    },
    {
      icon: IconCardListIcon9,
      altTxt:
        "Ability to connect with your team in real time, during normal business hours",
      heading: (
        <>
          Ability To Connect With
          <br /> Your Team In Real
          <br /> Time, During Your Normal <br />
          Business Hours
        </>
      ),
    },
    {
      icon: IconCardListIcon1,
      altTxt: "Guaranteed substantial savings on payroll",
      heading: (
        <>
          Guaranteed <br />
          Substantial savings <br /> on Payroll
        </>
      ),
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Reduction of employee turnover rate",
      heading: "Reduction of Employee Turnover Rate",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Reduced time spent on HR functions as we handle all",
      heading: (
        <>
          Reduced Time Spent On <br /> HR Functions as we <br />
          Handle All
        </>
      ),
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Minimise overheads by upto 60%",
      heading: (
        <>
          Minimise Overheads by
          <br /> Upto 60%
        </>
      ),
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Increased productivity and profitability",
      heading: (
        <>
          Increased <br />
          Productivity and <br />
          Profitability
        </>
      ),
    },
    {
      icon: IconCardListIcon7,
      altTxt:
        "Complete transparency - No hidden fees, just one monthly payment",
      heading: (
        <>
          Complete Transparency -<br /> No Hidden Fees, Just
          <br /> One Monthly Payment
        </>
      ),
    },
    {
      icon: IconCardListIcon8,
      altTxt: "Highly skilled, motivated and productive employees",
      heading: (
        <>
          Highly Skilled, Motivated
          <br /> and Productive
          <br /> Employees
        </>
      ),
    },
  ];
  // Mobile movie slider
  const movieSlider1 = {
    imageUrl: DigirytePodsNoShadow,
  };
  const mobMovieSlider1 = {
    imageUrl: BusinessBenifited,
  };

  /**
   * Medium View
   */

  const MediumviewData = {
    title: (
      <div className="ttc">
        Are you facing any of these business challenges? <br />
        <span className="red-line3letter red-3letter-mbl">Dig</span>iryte Pod
        will address your issue
      </div>
    ),
    componentSpecific: "no",
    underline: true,
    individualBoxData: {
      IndividualBoxTitle: "If you got a unique challenge,  ",
      IndividualBoxBtnLabel: "GET IN TOUCH",
      IndividualBoxBtnVariant: "contained",
      IndividualBoxBtnColor: "primary",
      route: "/contact-us",
    },
    data: [
      {
        MediumviewImage: MediumviewImage2,
        MediumviewAlt: "On-Shore Model",
        // OnlyHeading:"yes",
        MediumviewHeading:
          // <span style={{fontSize:"35px", lineHeight:"2"}}>
          "Are you facing a delivery issue in terms of Cost, Quality and Timeliness?",
        // </span>,
        MediumviewContent: (
          <>
            Digiryte offers cost-effective solutions that optimize your
            resources, ensuring you achieve the best value for your investment.
            Our commitment to quality is unwavering, as we employ rigorous
            testing and quality assurance processes to deliver flawless results
            delivered on time.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage3,
        MediumviewAlt: "Off-Shore Model",
        // OnlyHeading:"yes",
        MediumviewHeading:
          "You want to be a Market leader by embracing constant tech change (Growth, Scalability, Resilience).",
        MediumviewContent: (
          <>
            Digiryte's strategic approach ensures that your business not only
            keeps up with technology changes but also leverages them effectively
            to drive growth and expand your market presence.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage1,
        MediumviewAlt: "Dual-Shore Model",
        // OnlyHeading:"yes",
        MediumviewHeading:
          " I have a business challenge but I don’t know the problem and I don’t know the solution.",
        MediumviewContent: (
          <>
            Digiryte helps you solve unidentified business challenges through
            digital expertise and tailored solutions.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
    ],
  };

  const ImageSliderData = {
    ImageClass: "slider-img-short-logos team-aug-slider-style",
    responsive: {
      0: { items: 2 },
      360: { items: 3 },
      600: { items: 2 },
      700: { items: 3 },
      1024: { items: 4 },
      1360: { items: 5 },
      1600: { items: 6 },
    },
    galleryItems: [
      {
        src: `${ARUP}`,
        alt: "ARUP",
      },
      {
        src: `${NetworkPlus}`,
        alt: "NetworkPlus",
      },
      // {
      //   src: `${Hedgehog}`,
      //   alt: "Hedgehog"
      // },
      {
        src: `${MROPRO}`,
        alt: "MROPRO",
      },
      {
        src: `${CERN}`,
        alt: "CERN",
      },
      // {
      //   src: `${SliderImg6}`,
      //   alt: "Mic Computers"
      // },
    ],
  };

  /**
   * Infoview1
   */
  const InfoviewData2 = {
    InfoviewTitle: (
      <>
        {" "}
        <span className="red-line3letter">Mor</span>e From This Service{" "}
      </>
    ),
    InfoviewContent: (
      <>
        Digiryte has a unique as a unique and proven framework that as a mix of
        technology, domain SME, Operation SME and Pod leader will not only
        deliver the product but also ensure this product helps your business to
        grow.
      </>
    ),
  };
  const InfoviewData4 = {
    InfoviewTitle: (
      <>
        {" "}
        <span className="red-line3letter">Dig</span>iryte Pod spinout
        process/approach{" "}
      </>
    ),
    InfoviewContent: (
      <>
        Our Consulting team provides targeted services (on a non-commercial
        basis with a commitment to set up a Pod with us) to help define your
        project, covering everything from business needs and resource
        requirements through to the solution, project planning and overall
        financial investment.
      </>
    ),
  };

  const InfoviewData3 = {
    InfoviewTitle: (
      <>
        What Exactly is a Digiryte Pod and <br />
        <span className="red-line3letter">How</span> it Will Solve Your Problem?
      </>
    ),
    InfoviewContent: (
      <>
        The dedicated Team approach provides a scalable flexible solution that
        can efficiently fill gaps of specialised skills. Explore the categories
        of our comprehensive technical expertise.
      </>
    ),
  };
  const ClickToViewCardData = [
    {
      title: "Self-Sustainable Teams",
      heading: (
        <>
          <span className="black-line3letter">Sel</span>f-Sustainable Teams:
        </>
      ),
      content:
        "Self-sufficient and self-sustainable agile team that has the autonomy to build and run its product or service, along with complete accountability for its performance.",
      image: ClickToViewCardImg1,
    },
    {
      title: "Cross-functional Teams",
      heading: (
        <>
          <span className="black-line3letter">Cro</span>ss-functional Teams:
        </>
      ),
      content:
        "Cross-functional and cross-skilled resources collectively working to achieve the sprint and release goals (sprint and release are terms associated with the agile way of working).",
      image: ClickToViewCardImg2,
    },
    {
      title: "Flexible team sizes",
      heading: (
        <>
          <span className="black-line3letter">Fle</span>xible team sizes:
        </>
      ),
      content:
        "Consists of 10-20 people, depending on the complexity and number of applications or services delivered.",
      image: ClickToViewCardImg3,
    },
    {
      title: "Complete development cycle ",
      heading: (
        <>
          <span className="black-line3letter">Com</span>plete development cycle:
        </>
      ),
      content:
        "Drives the DevOps model in a seamless fashion with an all-inclusive structure of business development, testing and operations.",
      image: ClickToViewCardImg4,
    },
    {
      title: "Retained team across projects",
      heading: (
        <>
          <span className="black-line3letter">Ret</span>ained team across
          projects:
        </>
      ),
      content:
        "Teams work on a single, integrated product backlog, compared to multiple project teams working on multiple project or application backlogs.",
      image: ClickToViewCardImg5,
    },
    {
      title: "Domain and Operations SME",
      heading: (
        <>
          <span className="black-line3letter">Dom</span>ain and Operations SME
        </>
      ),
      content:
        "Adding domain and operational SME to this pod group will elevate and achieve the efficacy of the Product",
      image: ClickToViewCardImg6,
    },
  ];

  const EightHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Rapid Growth",
    HoverCardWithBtntitle2: "Incorporate new technologies",
    HoverCardWithBtntitle3: "Improve product reliability",
    HoverCardWithBtntitle4: "Speed",
    HoverCardWithBtntitle5: "Improved Efficiency",
    HoverCardWithBtntitle6: "Non-disruptive",
    HoverCardWithBtntitle7: "Secure by Design",
    HoverCardWithBtntitle8: "ISO 27001 & 9001",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon11,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon12,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon13,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon14,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon15,
    HoverCardWithBtnIcon6: HoverCardWithBtnIcon16,
    HoverCardWithBtnIcon7: HoverCardWithBtnIcon17,
    HoverCardWithBtnIcon8: HoverCardWithBtnIcon18,
    HoverCardWithBtnContent1:
      "You must have a process for every role in your business in order to grow your operations. Digiryte helps you define and measure every step of your workflow and allows you to identify problems, progress and delays.",
    HoverCardWithBtnContent2:
      "Scalability is a key driver of business growth. By adopting emerging technologies and constantly innovating, we ensure that our clients stay ahead of competition and have a high level of market visibility.",
    HoverCardWithBtnContent3:
      "A number of decisions are made through the development process that result in reliability. We are creating a reliable product by using the right insights and the ability of existing technology to scale up your dream product.",
    HoverCardWithBtnContent4:
      "One must know when to grow and this can play a key role in the long-term sustainability of your business. We help our customers find the right balance of acceleration to maximise their business and capture market share without breaking the bank.",
    HoverCardWithBtnContent5:
      "The team at Digiryte is committed to help make your product journey be trainable, trackable, and reportable. We ensure we help you understand the best possible use of your resources, decreasing costs, and increasing productivity.",
    HoverCardWithBtnContent6:
      "Small changes to your product means minimum disturbance or difficulty to users, but cumulatively significant.We ensure the user experience is not disturbed but only enhanced with these changes.",
    HoverCardWithBtnContent7:
      "During the scaling process we ensure the product is robust enough to survive and thrive in the market. Our aim is to create an efficient digital product that is secure and serves a large user base.",
    HoverCardWithBtnContent8:
      "We strive to continuously adapt and assure our clients that their products are in line with the latest rules and best practises. All our work is ISO compliant and has the quality and safety of ISO 27001 and 9001 certifications.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    HoverCardWithBtnIconAlt6: "LMS Development ",
    HoverCardWithBtnIconAlt7: "LMS Development ",
    HoverCardWithBtnIconAlt8: "LMS Development ",
    HoverCardIconClass: "mb-10",
    HoverCardWithBtnContentClass: "btncontent mt-10",
    Extracardclassname: "",
    HoverCardExtraiconclassname: "pricing-card-icon-no-bottom",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  /**
   * Click To View Mega View Carousal
   */

  const ClickToViewMegaViewCarousalData = {
    data: [
      {
        title: "STEP 1: What is Digiryte Pods?",
        download: "no",
        bottom: "Collaboration Process",
        displayFIndoutMore: "no",
        card1data: {
          meta_title: "What is Digiryte Pods?",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "A custom setup agile team specifically set up to solve delivery challenges of IT leaders. This configuration enables IT leaders to use the POD and the agile team members within this POD to work dedicatedly on their challenges.",
          mega_view_image: ClickToViewMegaViewCarousalImg1,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "understand-what-a-digiryte-pod-is/",
          slug_type: "article",
        },
      },
      {
        title: "STEP 2: Benefits of Digiryte Pods",
        card1data: {
          meta_title: "Benefits of Digiryte Pods",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "There are a lot of benefits to using a platform like Digiryte Pods. These self learning and self managing agile teams bring with them loads of benefits to help solve the delivery challenges that IT leaders face on a daily basis.",
          mega_view_image: ClickToViewMegaViewCarousalImg2,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",

          slug: "how-can-it-leaders-benefit-from-using-digiryte-pods",
          slug_type: "article",
        },
      },
      {
        title: "STEP 3: PIM Framework",
        card1data: {
          meta_title: "PIM Framework",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "PIM Framework was specifically designed with keeping the IT leaders in our minds. The three unique stages of this framework enable Digiryte Pods to Plan what is needed to solve a delivery challenge, then Implement the decided strategy and finally Manage the outcome in sync with the goals of the IT leaders.",
          mega_view_image: ClickToViewMegaViewCarousalImg3,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug:
            "understand-the-pim-framework-is-and-how-it-is-used-in-digiryte-pods/",
          slug_type: "article",
        },
      },
      {
        title: "STEP 4: Types of Digiryte Pods",
        card1data: {
          meta_title: "Types of Digiryte Pods",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Pods can be custom built in any set of configurations for IT leaders based on their requirements. We use Agile methodologies not just in our work, but how we manage our Pods as well. The configuration also enables IT leaders to add, remove or replace skills that complement the requirements that the IT leaders currently are looking for.",
          mega_view_image: ClickToViewMegaViewCarousalImg4,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "what-are-the-different-types-of-digiryte-pods/",
          slug_type: "article",
        },
      },
    ],
  };

  return (
    <Layout
      seo={{
        title: "Digiryte Pods | Building & Scaling Remote Teams",
        ogtitle: "Digiryte Pods | Building & Scaling Remote Teams",
        description:
          "Digiryte assists in hiring, managing, and implementing all the relevant human resource functions for you to start building and scaling reliable and dynamic remote teams.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/digiryte-pods`,
        keywords:
          "Digiryte Pods, remote teams, hiring, managing, human resources, scaling teams, dynamic teams",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-50"></div>
      <div className="mt-50"></div>
      <MediumviewWithHover MediumviewData={MediumviewData} />
      <div className="mt-50">
        <Infoview InfoviewData={InfoviewData3} />
      </div>
      <div className="mt-30 body-container">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewDataStepper} />
      </div>
      <div className="body-container">
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Cor</span>e team
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Par</span>t-time specialist
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper1} />
          </Grid>
        </Grid>
      </div>
      <div className="body-container">
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Pod</span> leader
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper2} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Dom</span>ain Subject-matter
              Expert
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper3} />
          </Grid>
        </Grid>
      </div>
      <div className="body-container">
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Acc</span>ount Manager - UK
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper4} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Dat</span>a Protection Officer
              (DPO)
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper5} />
          </Grid>
        </Grid>
      </div>
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Ben</span>efits of scaling with
          Digiryte Pods
        </h3>{" "}
      </div>
      <div className="mt-50"></div>
      <EightPlainCards SixHoverCardData={EightHoverCardData} />
      {/* <div className="mt-70 body-container">
        <h3 className="title-header">
        Here are a few of the  complimentary <br/> benefits that you can get as part of <br/>
          <span className="red-line3letter">Dig</span>iryte Pods
        </h3>
      </div> */}

      {/* <div className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider1} />
      </div>   */}
      <div className="mt-50">
        <Infoview InfoviewData={InfoviewData4} />
      </div>

      <div className="mt-50" />
      <ClickToViewMegaViewCarousal
        ClickToViewMegaViewCarousalData={ClickToViewMegaViewCarousalData}
      />
      {/* <div className="mt-70">
        <Infoview InfoviewData={InfoviewData1} />
      </div>
  <SixPlainCards  SixHoverCardData={SixHoverCardData} /> */}
      {/* <Grid className="show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider} />
      </Grid> */}
      <div className="mt-70 body-container">
        <h3 className="title-header ">
          {" "}
          <span className="red-line3letter">Bus</span>inesses Benefitted
        </h3>

        <div className="mt-50">
          <MobileMovieSlider
            componentName="digiryte-pods-hght"
            movieSlider={mobMovieSlider1}
            slideid="slide1"
          />

          <ImageSlider ImageSliderData={ImageSliderData} />
        </div>
      </div>
      {/* <div className="mt-70 body-container">
        <h3 className="h3-header"> <span className="red-line3letter red-3letter-mbl">Key</span> Start-up Clients</h3>
      </div>
      <div className="mt-50 body-container">
      <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        <ImageSlider ImageSliderData={ImageSliderData} />
      </div> */}

      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>

      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container ttc">
            <span className="title-header">
              <span className="red-line3letter">Rec</span>ommended Reading
            </span>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData2} />
      </div>
      {/* <div className="body-container mt-70 ttc">
        <span className="title-header">
          <span className="red-line3letter">Mor</span>e From This Service
        </span>
      </div> */}
      <div className="mt-70">
        <BlurCardList
          loadMore
          componentId="1"
          BlurCardListData={BlurCardListData}
        />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default DigirytePods;
